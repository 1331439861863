<script>
import { DATE_FORMAT_FULL_RU, DATE_FORMAT_SHORT } from '@/constants/date'
import { GET_PREPARE_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import { autoType } from '@/constants/auto-type'
import {
  confirmedTimeslotDate,
  confirmedTimeslotTime,
  getFormattedTzDate,
  phoneRegex,
} from '@/core'
import { controlTableCultureStatusesList } from '@/views/control/components/tableCulture/data/ControlTableCulture'
import { mapGetters } from 'vuex'
import { timeslotStatuses } from '@/views/manager/data/manager'

export default {
  name: 'ManagerTableCell',
  props: {
    data: {
      type: [String, Array, Number],
      default: '',
    },
    row: {
      type: Object,
      default: () => {},
    },
    propKey: {
      type: String,
      default: '',
    },
    status: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      getPrepareCultures: GET_PREPARE_CULTURE_FROM_STATE,
    }),
  },
  methods: {
    getStatus(status) {
      return controlTableCultureStatusesList[status]
        ? controlTableCultureStatusesList[status]
        : controlTableCultureStatusesList.undefinedStatus
    },
    createTableCellTruckSpindlesCount(createElement) {
      const { step } = this.row?.extras
      const count =
        step && step.truck_spindles_count ? step.truck_spindles_count : '-'

      return [
        createElement('div', { class: ['truck_spindles_count'] }, [count]),
      ]
    },
    createTableCellNumTruck(createElement) {
      const { plate_trailer } = this.row

      return [
        createElement('div', { class: ['plate_trailer'] }, [plate_trailer]),
      ]
    },
    createTableCellNumAuto(createElement) {
      const { plate_truck } = this.row

      return [createElement('div', { class: ['plate_truck'] }, [plate_truck])]
    },
    createTableCellDriverName(createElement) {
      const driver_name = this.row.driver_name || 'не указано'

      return [createElement('div', { class: ['driver_name'] }, [driver_name])]
    },
    createTableCellExporterName(createElement) {
      const exporter_name = this.row.exporter_name || '-'

      return [createElement('div', [exporter_name])]
    },
    createTableCellSupplierName(createElement) {
      const supplier_name = this.row.supplier_name || '-'

      return [createElement('div', [supplier_name])]
    },
    createTableCellTimeslot(createElement) {
      const { window_from, window_to } = this.row

      const prepare_window_from = confirmedTimeslotTime(window_from)
      const prepare_window_to = confirmedTimeslotTime(window_to)
      const prepare_windows = confirmedTimeslotDate(window_from)

      let content = []

      if (prepare_window_from !== '' && prepare_window_to !== '') {
        content = [
          prepare_windows,
          `с ${prepare_window_from} по ${prepare_window_to}`,
        ]
      }

      return content.map((item, i) =>
        createElement('div', { class: `timeslot${i}` }, [item]),
      )
    },
    createTableCellBriefDate(createElement, key) {
      const content = this.row[key]

      const preparedContent = content
        ? getFormattedTzDate(content, DATE_FORMAT_SHORT)
        : '-'

      return [
        createElement('div', { class: ['plate-status', key] }, [
          preparedContent,
        ]),
      ]
    },
    createTableCellPhone(createElement, key) {
      const content = this.row[key]
      const prepare_content = phoneRegex(content)

      return [
        createElement('div', { class: ['phone', key] }, [prepare_content]),
      ]
    },
    createTableCellCulture(createElement) {
      const { culture_id, culture_id_old, culture_name } = this.row
      const currentCulture = `${
        this.getPrepareCultures[culture_id]?.name || culture_name
      }${
        this.getPrepareCultures[culture_id]?.harvest_year
          ? ' ' + this.getPrepareCultures[culture_id]?.harvest_year + ' года'
          : ''
      }`

      if (culture_id === culture_id_old || !culture_id_old) {
        return [createElement('div', { class: ['culture'] }, [currentCulture])]
      }

      const oldCulture = this.getPrepareCultures[culture_id_old]?.name
      let content = []

      if (currentCulture !== '' && oldCulture !== '') {
        content = [oldCulture, currentCulture]

        return content.map((item, i) =>
          createElement('div', { class: `culture-new-${i}` }, [item]),
        )
      }
    },
    createTableCellStatus(h) {
      const { truck_status, submit_status } = this.row
      const content = timeslotStatuses[truck_status] || timeslotStatuses.default

      return (
        <div
          class={`${truck_status} status ts-${submit_status} tag-${content.icon}`}
        >
          <i class={content.icon} />
          {content.title}
        </div>
      )
    },
    createTSStatus(h) {
      const { submit_status } = this.row
      const content =
        timeslotStatuses[submit_status] || timeslotStatuses.default

      return (
        <div class={`${submit_status} status tag-${content.icon}`}>
          <i class={content.icon} />
          {content.title}
        </div>
      )
    },
    createTableCellTypeAuto(createElement) {
      const { truck_type_code } = this.row
      const type =
        autoType.filter(item => item.val === truck_type_code)[0] ||
        'не определено'

      return [createElement('div', { class: ['type', type.val] }, [type.title])]
    },
    createTableCellDefault(createElement, prop) {
      const content = this.row[prop] || 'не определено'

      return [createElement('div', { class: [prop] }, [content])]
    },
    createCommonSupplierInn(createElement) {
      const inn = this.row.extras?.common?.common_supplier_inn || '-'

      return [createElement('div', { class: ['plate_trailer'] }, [inn])]
    },
    createCommonSupplierName(createElement) {
      const name = this.row.extras?.common?.common_supplier_name || '-'

      return [createElement('div', { class: ['plate_trailer'] }, [name])]
    },
    createTableCellCreatedAt(createElement) {
      const createdAt = this.row.date_cre

      return [
        createElement('div', [
          getFormattedTzDate(createdAt, DATE_FORMAT_FULL_RU),
        ]),
      ]
    },
  },
  render(createElement) {
    let classNames = [this.propKey]
    let renderData = [this.data]

    if (Array.isArray(this.data)) {
      renderData = this.data.map(item => createElement('div', item))
    }
    switch (this.propKey) {
      case 'num_auto': {
        renderData = this.createTableCellNumAuto(createElement)
        break
      }
      case 'driver_name': {
        renderData = this.createTableCellDriverName(createElement)
        break
      }
      case 'truck_spindles_count': {
        renderData = this.createTableCellTruckSpindlesCount(createElement)
        break
      }
      case 'plate_trailer': {
        renderData = this.createTableCellNumTruck(createElement)
        break
      }

      case 'exporter_name': {
        renderData = this.createTableCellExporterName(createElement)
        break
      }

      case 'supplier_name': {
        renderData = this.createTableCellSupplierName(createElement)
        break
      }

      case 'timeslot': {
        renderData = this.createTableCellTimeslot(createElement)
        break
      }

      case 'culture': {
        renderData = this.createTableCellCulture(createElement)
        break
      }

      case 'truck_status': {
        return this.createTableCellStatus(createElement)
      }

      case 'submit_status': {
        return this.createTSStatus(createElement)
      }

      case 'truck_type_code': {
        renderData = this.createTableCellTypeAuto(createElement)
        break
      }

      case 'parking_time':
      case 'buffer_time':
      case 'terminal_time': {
        renderData = this.createTableCellBriefDate(createElement, this.propKey)
        break
      }

      case 'date_cre': {
        renderData = this.createTableCellCreatedAt(createElement)
        break
      }

      case 'driver_phone':
      case 'phone': {
        renderData = this.createTableCellPhone(createElement, this.propKey)
        break
      }

      case 'extras_common_supplier_inn': {
        renderData = this.createCommonSupplierInn(createElement)
        break
      }

      case 'extras_common_supplier_name': {
        renderData = this.createCommonSupplierName(createElement)
        break
      }

      default: {
        renderData = this.createTableCellDefault(createElement, this.propKey)
        break
      }
    }

    return createElement('div', { class: classNames }, renderData)
  },
}
</script>
